export const getCookie = cname => {
  var name = cname + "="
  var decodedCookie = decodeURIComponent(window.document.cookie)
  var ca = decodedCookie.split(";")
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

/**
 * 
 * @param {*} cname Cookie name
 * @param {*} cvalue Cookie value
 * @param {*} expiry Expiry (in days). Can also send an object like { value: 1, unit: 'minute' }.
 */
export const setCookie = (cname, cvalue, expiry) => {
  var d = new Date()
  if (typeof expiry === 'object') {
    const { value, unit } = expiry
    if (unit === 'minute' || unit === 'minutes') {
      d.setTime(d.getTime() + value * 1000 * 60)
    } else if (unit === 'hour' || unit === 'hours') {
      d.setTime(d.getTime() + value * 1000 * 60 * 60)
    } else if (unit === 'day' || unit === 'days') {
      d.setTime(d.getTime() + value * 1000 * 60 * 60 * 24)
    }
  } else {
    d.setTime(d.getTime() + expiry * 1000 * 60 * 60 * 24)
  }
  var expires = "expires=" + d.toUTCString()
  window.document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
}

export const isLoggedIn = async () => {
  return new Promise((resolve, reject) => {
    var cookie = getCookie("loggedIn")
    if (cookie && cookie === "true") {
      resolve(true)
      return
    }
    resolve(false)
  })
}

export const isWebsiteAnalyzerLoggedIn = async () => {
  return new Promise((resolve, reject) => {
    var cookie = getCookie("waLoggedIn")
    if (cookie && cookie === "true") {
      resolve(true)
      return
    }
    resolve(false)
  })
}

export const saveUTMData = () => {
  var utmData = {}

  if (getParameterByName("utm_source")) {
    utmData.utm_source = getParameterByName("utm_source")
  }

  if (getParameterByName("utm_medium")) {
    utmData.utm_medium = getParameterByName("utm_medium")
  }

  if (getParameterByName("utm_campaign")) {
    utmData.utm_campaign = getParameterByName("utm_campaign")
  }

  if (getParameterByName("utm_content")) {
    utmData.utm_content = getParameterByName("utm_content")
  }

  if (Object.keys(utmData).length) {
    setCookie("utmData", JSON.stringify(utmData), 365)

    // Set first visit cookie once
    var firstVisitUTMData = getCookie("firstVisitUTMData")
    if (!firstVisitUTMData) {
      setCookie("firstVisitUTMData", JSON.stringify(utmData), 365)
    }
  }
}

export const scrollUp = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  })
}

export const getURLParam = paramKey => {
  if (typeof window !== "undefined") {
    const params = new URLSearchParams(window.location.search)
    return params.get(paramKey) || null
  }
  return null
}

const addLeadingSlash = function (str) {
  if (typeof str == "string" && str[0] != "/") {
    str = "/" + str
  }
  return str
}

export const generateMlWidgetUrl = path => {
  if (typeof window !== "undefined") {
    path = new URL(path, window.location.origin).pathname
    let queryParam = new URL(path, window.location.origin)
    if (getURLParam("isMediaLibraryWidget") !== null) {
      queryParam.searchParams.set(
        "isMediaLibraryWidget",
        getURLParam("isMediaLibraryWidget")
      )
      queryParam.searchParams.set("redirectTo", "media-library-widget")
      if (getURLParam("mlWidgetInitialView") !== null) {
        queryParam.searchParams.set("mlWidgetInitialView", getURLParam("mlWidgetInitialView"))
      }
    }

    if (getURLParam("widgetHost") !== null) {
      queryParam.searchParams.set("widgetHost", getURLParam("widgetHost"))
    }
    return `${addLeadingSlash(path)}${decodeURIComponent(queryParam.search)}`
  }
  return path
}

export const getParameterByName = (name, url) => {
  if (typeof window === 'undefined') return "";
  if (!url) url = window.location.href
  name = name.replace(/[\[\]]/g, "\\$&")
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ""
  return decodeURIComponent(results[2].replace(/\+/g, " "))
}

// An implementation of Fisher-Yates Knuth shuffle
// https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
export const shuffle = array => {
  let currentIndex = array.length,
    randomIndex

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

export const ucFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getImageTransferSize = (imageURL) => {
	if(window.performance.getEntriesByName){
		var performanceEntries = window.performance.getEntriesByName(imageURL);
		if(performanceEntries.length) {
			if(performanceEntries[0].transferSize) {
				return performanceEntries[0].transferSize;
			}
		}
	}
	return;
}

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * // Create a Date object for January 1, 2024
    const date = new Date('2024-01-01');

    // Example 1: "1st Jan, 2024" (Default options)
    console.log(humanizeDate(date));
    // Output: "1st Jan, 2024"

    // Example 2: "1st January, 2024"
    console.log(humanizeDate(date, { monthFormat: "long" }));
    // Output: "1st January, 2024"

    // Example 3: "Jan 1, 2024"
    console.log(humanizeDate(date, {
      dayWithOrdinal: false,
      dayFirst: false
    }));
    // Output: "Jan 1, 2024"

    // Example 4: "Jan 1st, 2024"
    console.log(humanizeDate(date, { dayFirst: false }));
    // Output: "Jan 1st, 2024"

    // Example 5: "January 1st, 2024"
    console.log(humanizeDate(date, {
      monthFormat: "long",
      dayFirst: false
    }));
    // Output: "January 1st, 2024"
 * 
 */
export function humanizeDate(date, options = {}) {
  // Arrays of month names
  const monthNamesShort = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const monthNamesLong = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Function to get ordinal suffix for a number
  function getOrdinalSuffix(n) {
    const s = ["th", "st", "nd", "rd"],
          v = n % 100;
    return s[(v - 20) % 10] || s[v] || s[0];
  }

  // Extract date components
  const day = date.getDate();
  const dayWithOrdinal = day + getOrdinalSuffix(day);
  const monthIndex = date.getMonth();
  const monthShort = monthNamesShort[monthIndex];
  const monthLong = monthNamesLong[monthIndex];
  const year = date.getFullYear();

  // Default formatting options
  const defaultOptions = {
    dayWithOrdinal: true,    // true to include ordinal suffix (e.g., 1st)
    monthFormat: "short",    // "short" or "long"
    dayFirst: true,          // true for "1st Jan", false for "Jan 1st"
    includeComma: true,      // true to include a comma after the month
  };

  // Merge default options with user-provided options
  options = Object.assign({}, defaultOptions, options);

  // Select day and month parts based on options
  const dayPart = options.dayWithOrdinal ? dayWithOrdinal : day;
  const monthPart = options.monthFormat === "long" ? monthLong : monthShort;
  const comma = options.includeComma ? "," : "";

  // Construct the formatted date string
  let formattedDate;
  if (options.dayFirst) {
    formattedDate = `${dayPart} ${monthPart}${comma} ${year}`;
  } else {
    formattedDate = `${monthPart} ${dayPart}${comma} ${year}`;
  }

  return formattedDate;
}

export const isValidEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return email?.match(emailRegex)?.[0] ? true : false;
}
